import React from 'react';

import classes from './ImageSelect.module.scss';
import formElementStyles from '../FormElements.module.scss';

import Message from './Message';

function imageSelect(props) {

  const handleClick = (event, disabledMessage) => {
    if (!!disabledMessage) {
      return false;
    }

    if (props.keyName) {
      props.onClick(props.keyName, event.currentTarget.getAttribute('data-value'));
    } else {
      props.onClick(event.currentTarget.getAttribute('data-value'));
    }
  }
  
  const options = props.options.map((opt, i) => {
    const rootClassesToApply = [classes.Option];

    if (opt.value === props.value) {
      rootClassesToApply.push(classes.Selected);
    }

    if (!!opt.disabledMessage) {
      rootClassesToApply.push(classes.Disabled);
    }
    
    return (
      <div className={rootClassesToApply.join(' ')} 
        key={`${opt.image}-${i}`} 
        data-value={opt.value}
        onClick={(e) => handleClick(e, opt.disabledMessage)}
      >
          <div className={classes.Content}>
            <div className={classes.Title}>
              {opt.name}
              <Message type="info">{opt.infoMessage}</Message>  
            </div>
          <div className={classes.Image} style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/${opt.image})`,
            }}>
              <Message type="disabled">{opt.disabledMessage}</Message>
            </div>
          </div>
      </div>
    );
  });


  const classToApply = [classes.ImageSelect];
  
  return (
    <div className={formElementStyles.Field}>
      <label>{props.label}</label>
      <div className={classToApply.join(' ')}>
        {options}
      </div>
    </div>
  );
}

export default imageSelect;