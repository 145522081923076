import { combineReducers } from 'redux';
import configReducer from './reducers/config';
import httpReducer from './reducers/http';
import uiReducer from './reducers/ui';


export default combineReducers({
  config: configReducer,
  http: httpReducer,
  ui: uiReducer
});
