import * as actions from '../actions/ui';

const initialState = {
  sidebarTarget: '',
  showIconLabels: true,
  hideChainInfo: false,
  showNotifications: {
    error: false,
    valid: false,
    invalid: false,
    missingComponent: false,
    pdf: false,
    connectionError: false
  }
}

const uiReducer = (state = initialState, action) => {

  switch(action.type) {

    case actions.CHANGE_SELECTION:
      let newState = {...state};
      newState[action.key] = action.value;

      return newState;
  
    case actions.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarTarget: action.value
      };

    case actions.SET_NOTIFICATION_DISPLAY:
      return {
        ...state,
        showNotifications: {
          error: action.error,
          valid: action.valid,
          invalid: !action.valid,
          missingComponent: action.missingComponentLength,
          pdf: action.pdf,
          connectionError: !!action.connectionError,
          tooLarge: action.tooLarge,
        }
      }

    case actions.DISMISS_NOTIFICATION:
      return {
        ...state,
        showNotifications: {
          ...state.showNotifications,
          [action.key]: false
        }
      };
    
    default: return state;

  }
}

export default uiReducer;