import React, { Component } from 'react';

import { SVG } from '@svgdotjs/svg.js';
import '@svgdotjs/svg.panzoom.js';

import classes from './Page.module.scss';

import Loader from '../Loader/Loader';
// window.SVG = SVG;

class Page extends Component {

  constructor(props) {
    super(props);
    this.svg = React.createRef();
    // this.preview = SVG('#' + this.props.id);
  }


  createSvg() {
    return { __html: this.props.file };
  }

  createViewBox() {
    
    const width = this.props.width || 0;
    const height = this.props.height || 0;

    return `${0} ${0} ${width} ${height}`;
  }

  componentDidUpdate(prevProps) {

    
    if (this.props.loading || !this.svg.current) {
      return;
    } 
    const rect = this.svg.current.getBoundingClientRect();

    const ratio = {
      width: rect.width / this.props.width,
      height: rect.height / this.props.height
    };
    
    const zoom = Math.min(ratio.height, ratio.width);

  
    const svg = SVG('#' + this.svg.current.id).panZoom({ 
      zoomFactor: 0.3,
      zoomMin: 0.5,
      zoomMax: 20
    });
    
    if (prevProps.loading !== this.props.loading) {
      svg.zoom(zoom * 0.95, {
        x: this.props.width / 2,
        y: this.props.height / 2,
      });
    }


    const elementsToToggleDisplay = svg.find('.table-info, .dimension-text, .dimension-lines:not(.spacing-dimension), .brand-mark, image');
    const toggleAction = this.props.hideChainInfo ? 'addClass' : 'removeClass';
    
    elementsToToggleDisplay.each(function(i) {
      this[toggleAction]('not-displayed');
    });


    // document.getElementById(this.props.id)
    //   .addEventListener('contextmenu', e => e.preventDefault());
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.loading !== this.props.loading;
  }

  render() {
    return ( 
      this.props.loading ? <Loader label={this.props.loadingLabel} /> : 
      <div className={classes.Page}>
        <svg ref={this.svg} id={this.props.id}
          className={[classes.SvgPreview, 'svg-root'].join(' ')}
          width="100%" height="100%"
          viewBox={this.createViewBox()}
          style={{minWidth: '10px', minHeight: '10px'}}
          dangerouslySetInnerHTML={this.createSvg()}
          ></svg>
      </div> 
    )
  }
}

export default Page;