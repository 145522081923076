export default class {
	constructor(initial) {
		this.params = initial || {};
		
		const pathname = (window.location.href || '');
		const [rest, query] = pathname.split('?');

		if (!!query) {

			const queryParams = query.split(',');
	
			for (let param of queryParams) {
				const [key, value] = param.split('=');
				const forceString = key === 'chainSize';
				this.params[key] = this.convert(value, forceString);
			}
		}
	}
	
	isBoolean(value) {
		return value === 'true' || value === 'false';
	}

	convert(value, forceString) {

		if (forceString) {
			return value;
		}

		if (this.isBoolean(value)) {
			return value === 'true';
		}

		if (isNaN(value)) {
			return value;
		}

		return Number(value);
	}
}