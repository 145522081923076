import React from 'react';

// import stylesFormElements from '../FormElements.scss';
import classes from './ComboInput.module.scss';

import Input from '../Input/Input';
import Wrapper from '../../../hoc/Wrapper/Wrapper';

function ComboInput(props) {

  const opts = props.datalist.map(o => <option key={o} value={o} />);

  return (
    <Wrapper>
      <Input {... props} />
      <datalist className={classes.Datalist} id={props.datalistId}>
        {opts}
      </datalist>
    </Wrapper>
  );
}

export default ComboInput;