import React from 'react';

import { Button } from '../../FormElements';
import Icon from '../../Icon/Icon';

import classes from './Selector.module.scss';

function selector (props) {

  const attListItems = props.attachments.map((att, index) => {
    // const uuid = att.getIn(['spacing', 'uuid']);

    const fullKey = `${index}`;
    // console.log(props.selectedUuid)

    const classList = [classes.ListItem];
    if (fullKey === props.selectedUuid) {
      classList.push(classes.Selected);
    }

    return (
      <li className={classList.join(' ')}
        key={`${fullKey}`}
        onClick={() => props.selectAttachment(index)}
        onMouseOver={() => props.highlightAttachment(index, 'in')}
        onMouseOut={() => props.highlightAttachment(index, 'out')}
      >
        {att.get('type')}
        <Button 
          btnStyle="Icon"
          onClick={() => props.deleteAttachment(index, 'delete')}>
          <Icon icon="trash" size="1x" />
        </Button>
      </li>
    );
  });
  
  return (
    <div>
      <Button 
        icon="trash" 
        btnStyle="Standard" 
        size="lg"
        onClick={() => props.deleteAttachment(null, 'delete')}
        onMouseOver={() => props.highlightAttachment(null, 'in')}
        onMouseOut={() => props.highlightAttachment(null, 'out')}
      >DELETE ALL</Button>
      <ul className={classes.UnorderedList}>{attListItems}</ul>
    </div>
  );
}

export default selector;