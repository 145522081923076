import React from 'react';

import classes from './Loader.module.scss';

function loader (props) {
  return (
    <div className={classes.Loader}>
      <div className={classes.Spinner}></div>
      <div className={classes.Label}>{props.label}</div>
    </div>  
  );
}

export default loader;
