

export const setClassNames = (...classes) => {

  const allClasses = [];

  for (let c of classes) {
    if (!!c) {
      allClasses.push(c);
    }
  }

  return allClasses.join(' ');
}
