import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as uiActions from '../../store/actions/ui';
import * as configActions from '../../store/actions/config';

import Wrapper from '../../hoc/Wrapper/Wrapper';
import { Button, Checkbox, Input, Select } from '../../components/FormElements';
import ActionBar from '../../components/ActionBar/ActionBar';
import DrawingUnitsSelector from '../../components/DrawingUnitsSelector';

import classes from './LengthOptions.module.scss';

class LengthOptions extends Component {

  render() {

    const finishingLinkOptions = this.props.previewData.availableFinishingLinks.map(s => {
      return {value: s.name, displayValue: `${s.name} (${s.symbol})`}
    }); 

    const DP = this.props.config.drawingUnits === 'METRIC' ? 2 : 3;

    const lengthCalc = () => {
      
      const divisor = this.props.config.drawingUnits === 'METRIC' ? 1 : 25.4;
      const unit = this.props.config.drawingUnits === 'METRIC' ? 'mm' : 'in.';
      const repeatLength = (this.props.previewData.pitch * this.props.previewData.repeatableSection / divisor);

      const decimalRegex = /\.*0+$/;
      const repeatLengthStr = repeatLength.toFixed(DP).replace(decimalRegex, '');
      const totalLengthStr = (repeatLength * this.props.config.numberRepeats).toFixed(DP).replace(decimalRegex, '');

      return `${repeatLengthStr} ${unit} = 
        ${totalLengthStr} ${unit}`;
      
    }

    const finishOnInnerLink = this.props.config.finishingLink === 'Inner Link';

    return (
      <Wrapper>
        <Select label="Finishing Link" options={finishingLinkOptions}
          disabledOptions={Number(this.props.config.numberRepeats) === 1 ? ['Inner Link'] : null}
          keyName="finishingLink"
          onChange={this.props.onChangeConfigSelection} 
          value={this.props.config.finishingLink}/>

        <Checkbox label="Finishing Link Separate"
          keyName="isFinishingLinkSeparate"
          onChange={this.props.onChangeConfigSelection} 
          value={!finishOnInnerLink && this.props.config.isFinishingLinkSeparate}
          disabled={finishOnInnerLink}
        />
         
        <div className={classes.Length}>
          <Input label="Number of Repeats" width="40%"
            type="integer" keyName="numberRepeats" 
            min={1} step={1}
            value={this.props.config.numberRepeats} onChange={this.props.onChangeConfigSelection} />
          <div className={classes.LengthCalc}>
            <div>
                &times; { `${this.props.previewData.repeatableSection} = ${(this.props.previewData.repeatableSection * this.props.config.numberRepeats).toFixed(0)} Pitches` }
            </div>
            <div>
                &times; { lengthCalc() }
            </div>
          </div>
        </div>

        <DrawingUnitsSelector
          onChange={this.props.onChangeConfigSelection}
          value={this.props.config.drawingUnits}
        />

        <ActionBar>
          <Button btnStyle="Plain" onClick={this.props.cancelModification}>CANCEL</Button>
          <Button btnStyle="Standard" onClick={this.props.applyModification}>APPLY</Button>
        </ActionBar>
      </Wrapper>
    );

  }
  
};

const mapStateToProps = state => {
  return {
    config: state.config,
    availableGlobalSelections: state.http.globalSelections,
    previewData: state.http.previewData
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeUISelection: (key, value) => {
      return dispatch({type: uiActions.CHANGE_SELECTION, key: key, value: value})
    },
    onChangeConfigSelection: (key, value) => {
      return dispatch({
        type: configActions.CHANGE_CONFIG_SELECTIONS,
        [key]: value,
      });
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LengthOptions);