import { saveAs } from 'file-saver';

import * as actions from '../actions/http';

const initialState = {
  previewData: {
    file: null,
    width: 0,
    height: 0,
    scale: 1,
    attachmentTypes: [],
    missingComponents: [],
    isValid: true,
    isError: false,
    availableFinishingLinks: [],
    additionalPartNumbers: '',
    partNumber: null,
    attachments: [],
    partNumberMatches: []
  },
  globalSelections: {
    availableSizes: [],
    availableBrands: [],
    availableSalesOffices: [],
    partNumberList: [],
  },
  loading: 0,
  loadingLabel: '',
  pdf: null,
  connectionError: null
};

// if (process.env.REACT_APP_USER === 'renold') {
//   initialState.salesPrices = [];
// }

const httpReducer = (state = initialState, action) => {

  switch(action.type) {
    case actions.FETCH_PREVIEW_START:
      return {
        ...state,
        loading: state.loading + 1,
        loadingLabel: 'Generating Preview',
        connectionError: null
      };
      
    case actions.FETCH_PREVIEW_SUCCESS:
      return {
        ...state,
        previewData: action.value,
        loading: state.loading - 1,
        pdf: null
      };

    case actions.FETCH_PREVIEW_FAILURE:
      return {
        ...state,
        previewData: {
          ...state.previewData,
          file: null
        },
        loading: state.loading - 1,
        pdf: null,
        connectionError: action.error.message
      };

    case actions.FETCH_GLOBAL_SELECTIONS_DATA_START:
      return {
        ...state,
        loading: state.loading + 1,
        connectionError: null
      };

    case actions.FETCH_GLOBAL_SELECTIONS_DATA_SUCCESS:

      return {
        ...state,
        globalSelections: action.value,
        loading: state.loading - 1
      }

    case actions.FETCH_GLOBAL_SELECTIONS_DATA_FAILURE: case actions.GENERATE_PDF_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        connectionError: action.error.message
      }

    case actions.GENERATE_PDF_START:
      return {
        ...state,
        loading: state.loading + 1,
        loadingLabel: 'Creating PDF',
        connectionError: null
      };

    case actions.GENERATE_PDF_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        pdf: action.pdf
      };

    
    case actions.SAVE_PDF:
      const date = new Date();

      const convertToDoubleFigure = (num) => {
        return num.toString().length === 1
          ? '0' + num
          : num.toString();
      }

      const fileTime = date.getFullYear() + convertToDoubleFigure(date.getMonth() + 1) 
        + convertToDoubleFigure(date.getDate() + 1)
        + convertToDoubleFigure(date.getHours())
        + convertToDoubleFigure(date.getMinutes())
        + convertToDoubleFigure(date.getSeconds());

      const blob = new Blob([action.pdf], { type: 'application/pdf' });
      saveAs(blob, `RENOLD ${action.chainSize} - ${fileTime}.pdf`);
      return {
        ...state,
        pdf: null
      };

    default: return state;
  }
}


export default httpReducer;