import React from 'react';

import classesFormElements from '../FormElements.module.scss';
import classes from './Checkbox.module.scss';

function checkbox(props) {

  function returnValue(event) {
    let value = event.target.checked;
    props.onChange(props.keyName, value);
  }

  const renderLabel = props.label ?
    <label className={classes.Label}>{props.label}</label> : null;

  return (
    <div className={[classes.Input, classesFormElements.Field].join(' ')}>
      <input
        type="checkbox"
        className={[classes.Checkbox, classesFormElements.FixedHeight].join(' ')}
        checked={props.value}
        placeholder={props.placeholder}
        disabled={!!props.disabled}
        onChange={returnValue} />{renderLabel}
    </div>
  );
}

export default checkbox;