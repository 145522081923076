import React, { Component } from 'react';
import { Select } from '../FormElements';

export default class DrawingUnitsSelector extends Component {

  unitOptions = [
    { value: 'METRIC', displayValue: 'METRIC (mm, m, kg)' },
    { value: 'IMPERIAL', displayValue: 'IMPERIAL (in., ft., lbs.)' }
  ];

  render() {
    return (
      <Select label="Drawing Units" options={this.unitOptions}
        keyName="drawingUnits"
        onChange={this.props.onChange} value={this.props.value}/>
    );
  }


};
