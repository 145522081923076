import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as configActions from '../../store/actions/config';

import { Button, Select, Checkbox } from '../../components/FormElements';
import ActionBar from '../../components/ActionBar/ActionBar';
import Alert from '../../components/Alert/Alert';

import Wrapper from '../../hoc/Wrapper/Wrapper';
import PitchSelection from './PitchSelection';

class ChainSelections extends Component {

  
  render() {
    const isTRC = name => name === 'TransmissionChains';
    
    const chainTypeOptions = this.props.availableGlobalSelections.availableChainTypes.map(t => {
      return t;
    });

    const sizeOptions = this.props.availableGlobalSelections.availableSizes
      .filter(s => s.brand === this.props.config.chainBrand && s.collection.name === this.props.config.chainType)
      .map((s, index) => {
        return {
          value: s.name,
          displayValue: s.name,
          group: s.standard,
          sortObj: s,
        };

      });


    const brandOptions = [];
    this.props.availableGlobalSelections.availableSizes.forEach(s => {
      if (!brandOptions.some(b => b.value === s.brand) && s.collection.name === this.props.config.chainType) {
        brandOptions.push({ value: s.brand, collection: s.collection.name });
      };
    });

    let pitchOptions = [];
    if (this.props.config.chainType === 'CvcChains') {
      pitchOptions = this.props.availableGlobalSelections.availableSizes
        .find(s => s.name === this.props.config.chainSize)
        .pitches.map(p => {
          return { value: p };
        });

    }

    const chain = this.props.availableGlobalSelections.availableSizes
      .find(s => s.name === this.props.config.chainSize);
    
    
    return (
      <Wrapper>

      

        <Select label="Chain Type" options={chainTypeOptions}
          keyName={this.props.config.chainType}
          onChange={this.props.onChangeChainType} value={this.props.config.chainType}/>
        
        <Select label="Brand" options={brandOptions}
          keyName={this.props.config.chainType}
          onChange={this.props.onChangeChainBrand} value={this.props.config.chainBrand}/>
        
        <Select label="Size" options={sizeOptions}
          keyName="chainSize"
          onChange={this.props.onChangeChainSize} value={this.props.config.chainSize}
          sortKey={[
            {
              filter: ({ sortObj: s }) => isTRC(s.collection.name) && /\dA/.test(s.name) && s.standard.indexOf('606') > -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitch - b.pitch,
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => isTRC(s.collection.name) && /\dB/.test(s.name) && s.standard.indexOf('606') > -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitch - b.pitch,
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => isTRC(s.collection.name) && /\dB/.test(s.name) && s.standard.indexOf('Renold') > -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitch - b.pitch,
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => isTRC(s.collection.name) && /\dB/.test(s.name) && s.standard.indexOf('1275') > -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitch - b.pitch,
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => isTRC(s.collection.name) && /[CA]\d+/.test(s.name) && s.standard.indexOf('1275') > -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitch - b.pitch,
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => {
                return isTRC(s.collection.name) && (
                  !/[CA]\d+/.test(s.name) && 
                  !/\dB/.test(s.name) && 
                  !/\dA/.test(s.name)
                );
              },
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitch - b.pitch,
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => !isTRC(s.collection.name) && s.standard.indexOf('BS') > -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitches[0] - b.pitches[0],
              group: ({ sortObj: s }) => s.standard,
            },
            {
              filter: ({ sortObj: s }) => !isTRC(s.collection.name) && s.standard.indexOf('BS') === -1,
              sort: ({ sortObj: a }, { sortObj: b }) => a.pitches[0] - b.pitches[0],
              group: ({ sortObj: s }) => s.standard,
            },
          ]}
        />
        
        <PitchSelection
          options={pitchOptions}
          pitch={this.props.config.pitch}
          onChangeSelection={this.props.onChangeSelection}
        />

        {
          chain.rollerOptions.length > 1 &&
          <Select label="Roller Type" options={chain.rollerOptions}
          keyName="rollerType"
          onChange={this.props.onChangeSelection} value={this.props.config.rollerType} />
        }

        {
          chain.hasFlattedVersion &&
          <Checkbox label="With Flats"
            keyName="flatted"
            onChange={this.props.onChangeSelection} 
            value={this.props.config.flatted} />
        }

        <Alert type="Info">
          <b>Not sure what to choose?</b>
          <p>
            Let the <a href="http://chainid.renold.com" target="_">Chain ID</a> and <a href="http://www.renoldchainselector.com" target="_">Renold Chain Selector</a> tools help you decide!
          </p>
        </Alert>
        
        <ActionBar>
          <Button btnStyle="Plain" onClick={this.props.cancelModification}>CANCEL</Button>
          <Button btnStyle="Standard" onClick={this.props.applyModification}>APPLY</Button>
        </ActionBar>


      </Wrapper>
    );

  }
  
};

const mapStateToProps = state => {
  return {
    config: state.config,
    availableGlobalSelections: state.http.globalSelections,
    previewData: state.http.previewData
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeSelection: (key, value) => {
      return dispatch({type: configActions.CHANGE_CONFIG_SELECTIONS, [key]: value})
    },
    onChangeChainType: (key, value) => {
      return dispatch(configActions.setChainType(value));
    },
    onChangeChainBrand: (key, value) => {
      return dispatch(configActions.setChainBrand(key, value));
    },
    onChangeChainSize: (key, value) => {
      return dispatch(configActions.setChainSize(value));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChainSelections);