import React, { Component } from 'react';

import classes from './ControlPanel.module.scss';

import { Button } from '../FormElements';

class ControlPanel extends Component {

  render() {
    return (
      <div className={classes.ControlPanel}>

        <div className={classes.Group}>
          { this.props.children }
          <Button onClick={() => this.props.onToggleSidebar('chainSelections')} 
            btnStyle="Icon Toolbar"
            desc="Chain Options"
            icon="bars"
            label="Chain Options" 
            showLabel={this.props.showIconLabels}
            size="lg"
          />
          <Button onClick={() => this.props.onToggleSidebar('addAttachment')} 
            btnStyle="Icon Toolbar"
            desc="Add Attachment"
            icon="plus" label="Add Attachment" showLabel={this.props.showIconLabels} size="lg"
          />
          {
            this.props.numAttachments 
            ?  <Button onClick={() => this.props.onToggleSidebar('editAttachment')} 
                className={this.props.numAttachments > 0 ? null : classes.Hidden}
                btnStyle="Icon Toolbar"
                desc="Edit Attachment"
                icon="exchange-alt" label="Edit Attachment" showLabel={this.props.showIconLabels} size="lg"
              />
            : null
          }
          <Button onClick={() => this.props.onToggleSidebar('lengthOptions')} 
            btnStyle="Icon Toolbar"
            desc="Length Options"
            icon="tape"
            label="Length Options" 
            showLabel={this.props.showIconLabels}
            size="lg"
          />

        </div>

        <div className={[classes.Group, classes.GroupEnd].join(' ')}>

          {
            (this.props.hasQuote) && (
              <Button onClick={() => this.props.onToggleSidebar('quotation')}
                btnStyle="Icon Toolbar"
                desc="Quotation Details"
                icon="file-invoice-dollar" label="Quotation Details" showLabel={this.props.showIconLabels} size="lg"
              />  
            )
          }
          

          {
            this.props.isValid 
            ? <Button onClick={this.props.generatePdf}
              btnStyle="Icon Toolbar"
              desc="Create PDF"
              icon="cloud-download-alt" label="Create PDF" 
              showLabel={this.props.showIconLabels} size="lg"
            /> : null
        }

          <Button onClick={() => this.props.onToggleSidebar('generalSettings')} 
            btnStyle="Icon Toolbar"
            desc="General Settings"
            icon="cogs"
            label="General Settings"
            size="lg"
            showLabel={this.props.showIconLabels}
          />

        </div>
      </div>
    );
  }
}

export default ControlPanel;