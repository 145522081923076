import * as actions from '../actions/config';

import { fromJS } from 'immutable';
import UrlParameters from '../../UrlParameters';



const initialParams = {
  chainType: 'TransmissionChains',
  chainSize: '08B',
  pitch: 12.7,
  rollerType: 'roller',
  flatted: false,
  drawingUnits: 'METRIC',
  chainBrand: 'RENOLD',
  numberRepeats: 2,
  salesOffice: 'UK',
  attachments: [],
  isFinishingLinkSeparate: true,
  finishingLink: 'Spring Clip Link',
  unitOfMeasure: 'EACH',
  partNumber: '',
  additionalPartNumbers: '',
  history: [],
}

// if (process.env.REACT_APP_USER === 'renold') {
//   initialParams.isQuotation = true;
// }

const url = new UrlParameters(initialParams);

const configReducer = (state = url.params, action) => {
  
  let newState = {...state};

  switch (action.type) {
    case actions.CHANGE_CONFIG_SELECTIONS:
      const { type, ...values } = action;

      let allowChange = true;
      let attachments = state.attachments;
      
      // Ask if we really want to change the chain type (attachments are incompatible)
      if (!!values.chainType && state.attachments.length > 0) {
        allowChange = window.confirm('Changing the chain type will remove all attachments. Continue?');

        if (allowChange) {
          attachments = [];
        }
      }

      if (!allowChange) {
        // If we are not allowing the change, return the state before the change was made
        return state;
      }

      const history = state.history.concat([state]);

      return {
        ...state,
        ...values,
        attachments,
        history,
      };
    

    case actions.CANCEL_MODIFICATION:
      newState = state.history[0] || state;
      return newState;
    
    case actions.APPLY_MODIFICATION:
      return {
        ...state,
        partNumber: '',
        additionalPartNumbers: '',
        history: []
      };

    case actions.ADD_ATTACHMENT:
      let newAtt = [...state.attachments];
      if (action.editingIndex > -1) {
        newAtt[action.editingIndex] = action.value;
      } else {
        newAtt = state.attachments.concat(action.value);
      }

      return {
        ...state,
        attachments: newAtt,
        partNumber: '',
        additionalPartNumbers: ''
      };

    case actions.DELETE_ATTACHMENT:
    
      const attAfterDelete = action.index === null
        ? []
        :[
        ...state.attachments.slice(0, action.index),
        ...state.attachments.slice(action.index + 1)
      ];

      return {
        ...state,
        attachments: attAfterDelete,
        partNumber: '',
        additionalPartNumbers: ''
      };

    case actions.LOAD_PART_NUMBER:
      return {
        ...state,
        history: []
      }
    
      case actions.SAVE_PART_NUMBER:
      return {
        ...state
      }

    case actions.OVERWRITE_PREVIEW_PROPERTIES:
      // console.log(action)
      return {
        ...state,
        attachments: action.attachments.map(a => fromJS(a)),
        additionalPartNumbers: action.additionalPartNumbers,
        chainSize: action.chainSize,
        chainBrand: action.chainBrand,
        chainType: action.chainType,
        rollerType: action.rollerType,
        finishingLink: action.finishingLink,
        isFinishingLinkSeparate: action.isFinishingLinkSeparate,
        numberRepeats: action.numberRepeats,
        partNumber: action.partNumber,
        unitOfMeasure: action.unitOfMeasure,
        flatted: action.flatted,
        pitch: action.pitch,
      }

    default: return state;

  }
}

export default configReducer;