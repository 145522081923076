import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'classlist-polyfill';
import 'custom-event-polyfill';

import './string-repeat-polyfill';



import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';

import Editor from './containers/Editor/Editor';
// import registerServiceWorker from './registerServiceWorker';

import './index.scss';

import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

import reducer from './store/index';

// const reduxDevTools = typeof window !== undefined &&
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(
    thunkMiddleware
  ))
);


const previewUrl = process.env.REACT_APP_CHAINSVG_URL;
const quoteUrl = process.env.REACT_APP_QUOTE_URL;

export const httpHandlerPreview = axios.create({
  baseURL: previewUrl,
  timeout: 20000,
});

export const httpHandlerQuote = axios.create({
  baseURL: quoteUrl,
  timeout: 3000,
});


ReactDOM.render(
  <Provider store={store}>
    <Editor />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
