import React, { Component } from 'react';

import { Button } from '../FormElements';
import ActionBar from '../ActionBar/ActionBar';

import classes from './Workflow.module.scss';

class Workflow extends Component {

  constructor(props) {
    super(props);
    this.content = React.createRef();
  }

  handleStepBtnClick(direction) {
    this.props.onClickStepChange(this.props.step, React.Children.toArray(this.props.children).length, direction);
    this.content.current.scrollTop = 0;
  }

  render() {

    const children = React.Children.toArray(this.props.children);

    const backBtn = this.props.step > 0 ? (
      <Button 
        btnStyle="Plain"
        onClick={() => this.handleStepBtnClick('back')}>BACK</Button>
    ) : null;

    const nextBtn = this.props.step < children.length - 1 ? (
      <Button
        btnStyle="Standard"
        onClick={() => this.handleStepBtnClick('next')}>NEXT</Button>
    ) : <Button 
          btnStyle="Standard"
          onClick={() => this.props.onSubmit()}>{this.props.submitActionLabel || 'SUBMIT'}</Button>;

    return (
      <div className={classes.Workflow}>
        <div ref={this.content} className={classes.Content}>
          {children[this.props.step]}
        </div>
        {this.props.showActionBar 
          ? (<ActionBar>
              {backBtn}
              {nextBtn}
            </ActionBar>) : null}
      </div>
    );
  
  }
}

export default Workflow;