import React, { Component } from 'react';

import * as flat from 'array.prototype.flat';

import { connect } from 'react-redux';

import * as configActions from '../../store/actions/config';
import * as uiActions from '../../store/actions/ui';
// import * as httpActions from '../../store/actions/http';

import { Button, Checkbox, ComboInput, Select } from '../../components/FormElements';
import Wrapper from '../../hoc/Wrapper/Wrapper';
import ActionBar from '../../components/ActionBar/ActionBar';
import DrawingUnitsSelector from '../../components/DrawingUnitsSelector/DrawingUnitsSelector';
import Version from './Version';

class GeneralSettings extends Component {

  render() {

    const salesOfficeOptions = 
      this.props.availableGlobalSelections.availableSalesOffices.map(s => {
        return {value: s}
      }); 

    let partNumberOptions = [];
    
    if (!!this.props.availableGlobalSelections.partNumberList) {
      partNumberOptions = this.props.availableGlobalSelections.partNumberList.map(p => p.partNumber);
      const additionalPartNumbers = this.props.availableGlobalSelections.partNumberList.map(p => (p.additionalPartNumbers || '').split(' '));
      
      partNumberOptions = partNumberOptions.concat(flat(additionalPartNumbers, Infinity)).filter(p => !!p);

    }
    const saveLoadButton = (
      <Button 
        btnStyle="Standard"
        onClick={() => this.props.loadPartNumber(this.props.config.partNumber)}>
        LOAD
      </Button>
    );

    return (
      <Wrapper>
        <Select label="Sales Office" options={salesOfficeOptions}
          keyName="salesOffice"
          onChange={this.props.handleChangeConfigSelection} value={this.props.config.salesOffice}/>

        <DrawingUnitsSelector 
          onChange={this.props.handleChangeConfigSelection}
          value={this.props.config.drawingUnits} />
        
        <ActionBar>
          <Button btnStyle="Plain" onClick={this.props.cancelModification}>CANCEL</Button>
          <Button btnStyle="Standard" onClick={this.props.applyModification}>APPLY</Button>
        </ActionBar>

        <hr />

        <h3>App Settings</h3>
        <Checkbox
          label="Show Icon Labels"
          value={this.props.ui.showIconLabels} 
          keyName="showIconLabels"
          onChange={this.props.handleChangeUISelection} />
        
        <Checkbox
          label="Hide Chain Information"
          value={this.props.ui.hideChainInfo} 
          keyName="hideChainInfo"
          onChange={this.props.handleChangeUISelection} />

        <hr />

        <h3>Load Model</h3>

        <ComboInput type="text" 
          onChange={this.props.handleChangeConfigSelection} 
          keyName="partNumber" value={this.props.config.partNumber} 
          label="Part Number"
          datalist={partNumberOptions} 
          datalistId="partNumbers" />
        
        
        <ActionBar>
          {saveLoadButton}
        </ActionBar>

        <hr />
        <Version />
      </Wrapper>
    );
  
  }

}

const mapStateToProps = state => {
  return {
    config: state.config,
    availableGlobalSelections: state.http.globalSelections,
    previewData: state.http.previewData,
    ui: state.ui
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleChangeConfigSelection: (key, val) => dispatch({
      type: configActions.CHANGE_CONFIG_SELECTIONS, 
      [key]: val,
    }),
    handleChangeUISelection: (key, val) => dispatch({
      type: uiActions.CHANGE_SELECTION,
      key: key,
      value: val
    }),
    savePartNumber: (partNum) => dispatch(configActions.savePartNumber(partNum)),
    loadPartNumber: (partNum) => {
      return dispatch(configActions.loadPartNumber());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);