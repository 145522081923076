import { SVG } from '@svgdotjs/svg.js';



import { httpHandlerPreview, httpHandlerQuote } from '../../index';
import { setNotificationDisplay, toggleSidebar } from './ui';
import { overwritePreviewProperties, cancelModification } from './config';
// import * as uuidv4 from 'uuid/v4'; 
// import { version } from '../../config';

// const userId = window.localStorage.getItem('userId') || uuidv4();
// window.localStorage.setItem('userId', userId);

// const sessionId = window.sessionStorage.getItem('sessionId') || uuidv4();
// window.sessionStorage.setItem('sessionId', sessionId);

const clientInfo = {
  // userId,
  // sessionId,
  version: process.env.npm_package_version,
};

// const urlParameters = new UrlParameters();

export const FETCH_GLOBAL_SELECTIONS_DATA = 'FETCH_GLOBAL_SELECTIONS_DATA';
export const FETCH_GLOBAL_SELECTIONS_DATA_START = 'FETCH_GLOBAL_SELECTIONS_DATA_START';
export const FETCH_GLOBAL_SELECTIONS_DATA_SUCCESS = 'FETCH_GLOBAL_SELECTIONS_DATA_SUCCESS';
export const FETCH_GLOBAL_SELECTIONS_DATA_FAILURE = 'FETCH_GLOBAL_SELECTIONS_DATA_FAILURE';

export const FETCH_PREVIEW = 'FETCH_PREVIEW';
export const FETCH_PREVIEW_START = 'FETCH_PREVIEW_START';
export const FETCH_PREVIEW_SUCCESS = 'FETCH_PREVIEW_SUCCESS';
export const FETCH_PREVIEW_FAILURE = 'FETCH_PREVIEW_FAILURE';

export const LOAD_CHAIN = 'LOAD_CHAIN';
export const LOAD_CHAIN_START = 'LOAD_CHAIN_START';
export const LOAD_CHAIN_SUCCESS = 'LOAD_CHAIN_SUCCESS';
export const LOAD_CHAIN_FAILURE = 'LOAD_CHAIN_FAILURE';

export const GENERATE_PDF = 'GENERATE_PDF';
export const GENERATE_PDF_START = 'GENERATE_PDF_START';
export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_FAILURE = 'GENERATE_PDF_FAILURE';

export const SAVE_PDF = 'SAVE_PDF';

export const fetchPreview = (url) => {

  return async (dispatch, getState) => {
    dispatch(fetchPreviewStart());
    
    const { history, ...config } = getState().config;

    const route = url || 'preview';

    try {
      const preview = await httpHandlerPreview({
        url: route,
        data: {
          ...clientInfo,
          chain: {
            ...config,
            pitch: Number(config.pitch), // Convert to number for server
          },
        },
        method: 'POST'
      });

      try {
        const quote = await httpHandlerQuote({
          url: '',
          data: {
            attachments: config.attachments,
            chainType: config.chainSize,
            length: preview.data.repeatableSection * preview.data.numberRepeats,
            salesOffice: config.salesOffice,
          },
          method: 'POST'
        });
        preview.data.quotation = quote.data;
      } catch (e) {
        // DO NOTHING
      }
  

      dispatch(fetchPreviewSuccess(preview.data));
      dispatch(overwritePreviewProperties(preview.data));

      setNotifications(true, dispatch, preview.data, route);

    } catch (err) {
      dispatch(fetchPreviewFailure(err));
      setNotifications(false, dispatch, err, route);
    }
  }
}

function setNotifications(success, dispatch, data, url) {

  if (!success) {
    dispatch(setNotificationDisplay(true, false, 0, null, data.message));
    return;
  }

  const isDownload = url === 'download';

  dispatch(setNotificationDisplay(data.error, data.isValid, isDownload ? 0 : data.missingComponents.length, isDownload, null, data.tooLarge));
}

export const fetchPreviewStart = () => {
  return {
    type: FETCH_PREVIEW_START
  };
}

export const fetchPreviewSuccess = (value) => {
  // console.log(value)
  return {
    type: FETCH_PREVIEW_SUCCESS,
    value: value
  };
}

export const fetchPreviewFailure = (err) => {
  return {
    type: FETCH_PREVIEW_FAILURE,
    error: err
  };
}

export const fetchGlobalSelectionsData = () => {

  return (dispatch) => {

    dispatch(fetchGlobalSelectionsDataStart());

    return httpHandlerPreview.get(`get-global-settings-data`).then(res => {
      dispatch(fetchGlobalSelectionsDataSuccess(res.data));
      dispatch(toggleSidebar('chainSelections'));
    }).catch(err => {
      dispatch(fetchGlobalSelectionsDataFailure(err));
      setNotifications(false, dispatch, err, 'get-global-settings-data');
    });
  }

}

export const fetchGlobalSelectionsDataStart = () => {
  return {
    type: FETCH_GLOBAL_SELECTIONS_DATA_START
  };
}

export const fetchGlobalSelectionsDataSuccess = (value) => {
  return {
    type: FETCH_GLOBAL_SELECTIONS_DATA_SUCCESS,
    value: value
  };
}

export const fetchGlobalSelectionsDataFailure = (err) => {
  return {
    type: FETCH_GLOBAL_SELECTIONS_DATA_FAILURE,
    error: err
  };
}




export const generatePdf = () => {
  return (dispatch, getState) => {
    dispatch(cancelModification());
    dispatch(generatePdfStart());

    const state = getState().http.previewData;

    const { history, ...config } = getState().config;

    // console.log(getState().ui);
    config.hideChainInfo = getState().ui.hideChainInfo;


    const svgPreview = SVG(document.getElementById('svg-preview'));
    svgPreview.attr({
      width: state.width + 'mm',
      height: state.height + 'mm',
      viewBox: `0 0 ${ state.width / state.scale } ${ state.height / state.scale }`
    });
    
    return httpHandlerPreview({
      url: 'download',
      data: {
        ...clientInfo,
        chain: {
          ...config,
          pitch: Number(config.pitch), // Convert to number for server
        },
      },
      method: 'POST',
      headers: {
        'Accept': 'application/pdf',
      },
      responseType: 'arraybuffer',
    }).then(res => {

      
      dispatch(generatePdfSuccess(res.data));
      setNotifications(true, dispatch, res, 'download');

    }).catch(err => {
      dispatch(generatePdfFailure(err));
      setNotifications(false, dispatch, err, 'download');

    });
  }
}

export const generatePdfStart = () => {
  return {
    type: GENERATE_PDF_START
  };
}

export const generatePdfSuccess = (pdf) => {
  return {
    type: GENERATE_PDF_SUCCESS,
    pdf: pdf
  };
}

export const generatePdfFailure = (err) => {
  return {
    type: GENERATE_PDF_FAILURE,
    error: err
  };
}

export const savePdf = (pdf, chainSize) => {
  return {
    type: SAVE_PDF,
    pdf: pdf,
    chainSize: chainSize
  };
}

