import React from 'react';

import classes from './IconLabel.module.scss';

function iconLabel(props) {

  if (!props.label) {
    return null;
  }
  
  return (
    <div className={classes.IconLabel}>
      {props.label}
    </div>
  )
}

export default iconLabel;