import React, { Component } from 'react';
import { fromJS, List } from 'immutable';

import { ImageSelect } from '../FormElements';
import { Input, Select } from '../FormElements';
import Workflow from '../Workflow/Workflow';
import Wrapper from '../../hoc/Wrapper/Wrapper';
import Selector from './Selector/Selector';

import classes from './Attachment.module.scss';

class Attachment extends Component {

  initialState = {
    attachment: fromJS({
      type: 'K1',
      spacing: {
        up: true,
        left: true,
        out: true,
        // uuid: -1,
        positions: {
          every: 4,
          starting: [1]
        },
      },
      // isBothSides: false,    
      collection: 'TransmissionKPlates',
      isWide: false,
    }),
    step: 0,
    editingIndex: -1,
    // nextUuid: 0,
  };

  state = this.initialState;

  componentDidMount() {

    // Set default attachment selection to null when in edit mode
    if (this.props.mode === 'edit') {
      this.initialState.attachment = this.initialState.attachment.set('type', null);
    }

    this.setState({
      attachment: this.initialState.attachment
    });
  }



  // componentDidUpdate(prevProps, prevState) {
    
  //   if (this.props.mode === 'add') {
  //   // if (this.state.attachment.getIn(['spacing', 'uuid']) === -1 && this.props.mode === 'add') {
      
  //     // const newAttachment = this.state.attachment.setIn(['spacing', 'uuid'], prevState.nextUuid);

  //     this.setState(prevState => {
  //       return {
  //         attachment: newAttachment,
  //         // nextUuid: prevState.nextUuid + 1
  //       };
  //     });
  //   }
  // }



  componentWillUnmount() {
    this.resetState();
    this.handleHighlightAttachment(null, null, 'out');
  }



  get attachmentSidesToRender() {

    const sideOptions = this.isTypePin() 
      ? ['PIN_RIGHT', 'PIN_BOTH', 'PIN_LEFT'] 
      : ['RIGHT', 'BOTH', 'LEFT']; // Image file names

    return sideOptions.map(s => {
      const name = s.split('_').splice(-1)[0]; // Reduces <COMPONENT>_<SIDE> format to <SIDE> but does nothing if already <SIDE> only
      
      return {
        value: name,
        image: s + '.svg',
        name: /BOTH/.test(name) ? 'BOTH' : 'ONE'
      }
    });
  }



  get attachmentSelector() {
    return this.props.mode === 'edit' 
      ? <Selector 
          attachments={this.props.existingAttachments} 
          highlightAttachment={this.handleHighlightAttachment}
          selectAttachment={this.handleSelectAttachment}
          deleteAttachment={this.handleDeleteAttachment}
          selectedUuid={this.state.selectedUuid} />
      : null;
  }


  handleTypeChange = (val) => {

    const selectedAttachment = this.props.attachmentTypes[Number(val)];

    let attachment = this.state.attachment.set('type', selectedAttachment.name);
    attachment = attachment.set('isWide', selectedAttachment.isWide);
    attachment = attachment.set('collection', selectedAttachment.collection);


    const isCrankedLink = selectedAttachment.name === 'CRANKED LINK';

    // If it is a cranked link and we are adding it...
    if (isCrankedLink && this.props.mode === 'add') {
      attachment = attachment.setIn(['spacing', 'positions', 'every'], Math.round(this.props.repeatableSection / 2));
    }

    this.setState({
      attachment: attachment
    });

  } 



  handleAddAttachment = () => {
    const attachmentToAdd = this.state.attachment;
    this.props.onClickAddAttachment(attachmentToAdd, this.state.editingIndex);
    this.resetState();
  }


  handleClickStepChange = (curStep, totalSteps, direction) => {
    let step;
    if (direction === 'next') {
      step = curStep < totalSteps - 1 ? curStep + 1 : totalSteps - 1
    } else {
        step = curStep > 0 ? curStep - 1 : 0;
    }

    this.setState({
      step: step
    });
  };


  handleDeleteAttachment = (index, mode) => {
    this.props.onDeleteAttachment(index);
    this.setState((prevState) => {
      return {
        attachment: this.initialState.attachment,
        step: 0,
        editingIndex: -1
      }
    });
  }



  handleHighlightAttachment = (index, direction, action = 'hover') => {
    
    if (this.state.editingIndex !== index) {
      // uuid null when highlighting is being globally added or removed
      // const selector = uuid === null
      //   ? '[data-attachment="true"]'
      //   // : `[data-aid='${uuid}']`;
      //   // : `[data-cidx='${index}'][data-sid='${uuid}']`;
      //   : `[data-att-id='${index}']`;
      
      const selector = `[data-att-id='${index}']`;
      
      const nodes = document.querySelectorAll(selector);
      
      for (let n of nodes) {
        if (direction === 'out') {
          n.classList.remove(classes.HighlightSelect, classes.HighlightHover);
        } else {
          n.classList.add(action === 'select' ? classes.HighlightSelect : classes.HighlightHover);
        }
      }
    }
  }



  handlePositionsChange = (keyName, val) => {

    // console.log(keyName, val);
    
    if (keyName === 'every') {
      val = val === '' ? '' : Number(val);
    } else {
      const nums = val === '' ? '' : val.map(v => Number(v));
      // console.log(val, nums);
      val = List(nums);
    }

    const attachment = this.state.attachment.setIn(['spacing', 'positions', keyName], val)
    this.setState({
      attachment: attachment
    });
  }



  handleSelectAttachment = (index) => {

    this.handleHighlightAttachment(null, null, 'out');
    this.handleHighlightAttachment(index, 'in', 'select');

    this.setState({
      attachment: this.props.existingAttachments[index],
      editingIndex: index,
    });

  }



  handleSpacingChange = (keyName, val) => {

    if (/^UP$/.test(val) || /^OUT$/.test(val) || /^LEFT$/.test(val)) {
      val = true;
    } else if (/^DOWN$/.test(val) || /^IN$/.test(val) || /^RIGHT$/.test(val)) {
      val = false;
    } else if (/^BOTH$/.test(val)) {
      val = undefined;
    }

    let attachment = this.state.attachment.setIn(['spacing', keyName], val);

    // Change the horizontal orientation to 'out = true' if K plate both sides
    if (this.isKAttachment && keyName === 'side' && val === 'BOTH') {
      attachment = attachment.setIn(['spacing', 'out'], true);
    }

    this.setState({
      attachment: attachment
    });

  }



  get horizontalOptionsToRender() {
    return ['IN', 'OUT'].map(s => {
      
      const isKAttachment = this.isKAttachment
      const isIn = s === 'IN';
      const isBothSides = this.isBothSides;

      if ((isIn && !(isKAttachment && isBothSides)) || s !== 'IN') {
        return {
          value: s,
          image: s + '.svg',
          name: s
        }
      } else {
        return null
      }
    }).filter(s => s !== null);
  }


  get isCrankedLink() {
    return this.state.attachment.get('type') === 'CRANKED LINK';
  }

  isTypePin() {
    return (/PIN/i).test(this.state.attachment.get('type'));
  }



  get isBothSides() {
    return this.state.attachment.getIn(['spacing', 'side']) === 'BOTH'; 
  }

  get isKAttachment() {
    return /^K/.test(this.state.attachment.get('type'));
  }

  get attachmentTypesToRender() {

    // Disable anything with a disabled message
    // In practice, this is always cranked plates, and they are disabled
    // when a definition is already present (max 1 definition per chain)

    const editMode = this.props.mode === 'edit';
    
    const val = this.props.attachmentTypes.map((type, index) => {
      return {
        value: index,
        image: type.image,
        name: type.name,
        disabledMessage: (!editMode || (editMode && !this.isCrankedLink)) ? type.disabledMessage : false,
        infoMessage: (!editMode || (editMode && !this.isCrankedLink)) ? type.infoMessage : false
      };
    });

    return val;
  }




  get positionOptions() {
    let every = this.state.attachment.getIn(['spacing', 'positions', 'every']);
    // console.log('posOpt', every)

    if (every === '') {
      return [];
    } else {
      every = new Array(every);
    }

    for (let i = 0; i < every.length; i++) {
      every[i] = i;
    }

    return every.map(p => {
      return { value: p, displayValue: p + 1 };
    });
  }



  resetState = () => {

    let att = this.initialState.attachment;

    this.setState({
      attachment: att,
      step: 0,
      editingIndex: -1
    });
  }



  get sideAndOrientation() {

    const selectedHoriz = this.state.attachment.getIn(['spacing', 'out']) ? 'OUT' : 'IN';
    const selectedVert = this.state.attachment.getIn(['spacing', 'up']) ? 'UP' : 'DOWN';
    
    let selectedSide = this.state.attachment.getIn(['spacing', 'left']) ? 'LEFT' : 'RIGHT';
    if (this.state.attachment.getIn(['spacing', 'left']) === undefined) {
      selectedSide = 'BOTH';
    }

    const orientationOptions = this.isTypePin() ? null :
      (
        <Wrapper>
          <ImageSelect options={this.horizontalOptionsToRender} value={selectedHoriz} keyName="out"
                onClick={this.handleSpacingChange} scroll={false} label="Horizontal Orientation" />
              
          <ImageSelect options={this.verticalOptionsToRender} value={selectedVert} keyName="up"
            onClick={this.handleSpacingChange} scroll={false} label="Vertical Orientation" />
        </Wrapper>
      );

    return !this.isCrankedLink
    ? <Wrapper>
        <ImageSelect options={this.attachmentSidesToRender} value={selectedSide} keyName="left"
          onClick={this.handleSpacingChange} scroll={false} label="Side" />

        { orientationOptions }
      </Wrapper>
    : null
  }



  get verticalOptionsToRender() {
    return ['UP', 'DOWN'].map(s => {
      return {
        value: s,
        image: s + '.svg',
        name: s
      }
    });
  }




  render() {

    // console.log(this.state.attachment.get('type'));

    return (
      <Wrapper>

        <Workflow step={this.state.step} 
          onClickStepChange={this.handleClickStepChange}
          onSubmit={this.handleAddAttachment}
          submitActionLabel="ADD"
          showActionBar={this.mode === 'add' || !!this.state.attachment.get('type')}  
        >

          { this.attachmentSelector }

          <ImageSelect options={this.attachmentTypesToRender} value={this.props.attachmentTypes.findIndex(att => att.name === this.state.attachment.get('type'))} 
            onClick={this.handleTypeChange} scroll={true} label="Type" />
            
          { this.sideAndOrientation }

          <Wrapper>
            <Input 
              label="Every X Pitches"
              keyName="every"
              type="number"
              value={this.state.attachment.getIn(['spacing', 'positions', 'every'])} 
              onChange={this.handlePositionsChange} 
              disabled={false}
            />

            <Select 
              label="Starting on Pitches"
              keyName="starting"
              multiple={true}
              size={10}
              options={this.positionOptions}
              value={this.state.attachment.getIn(['spacing', 'positions', 'starting']).toArray()}
              onChange={this.handlePositionsChange}
            />

          </Wrapper>

        </Workflow>

      </Wrapper>
    );
  }

}

export default Attachment;