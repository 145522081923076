import React from 'react';

import classes from './Message.module.scss';

export default ({ children, type }) => {
  
  if (!children) {
    return null;
  }

  return (
    <div className={classes[getClassFromType(type)]}>
      { children }
    </div>
  );
}

function getClassFromType(type) {
  switch (type) {
    case 'disabled': return 'Disabled';
    case 'info': return 'Info';
    default: return 'Plain';
  }
}