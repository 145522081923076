export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CHANGE_SELECTION = 'CHANGE_SELECTION';

export const SET_NOTIFICATION_DISPLAY = 'SET_NOTIFICATION_DISPLAY';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';


export const toggleSidebar = (value) => {
  return {
    type: TOGGLE_SIDEBAR,
    value: value
  };
}

export const handleChangeSelection = (value, callback) => {
  return {
    type: CHANGE_SELECTION,
    value: value,
    callback: callback
  };
}

export const setNotificationDisplay = (error, valid, missingComponentLength, pdf, connectionError, tooLarge) => {
  return {
    type: SET_NOTIFICATION_DISPLAY,
    error,
    valid,
    missingComponentLength,
    pdf,
    connectionError,
    tooLarge,
  };
}

export const dismissNotification = (notificationKey) => {
  return {
    type: DISMISS_NOTIFICATION,
    key: notificationKey
  };
}