import React, { Component, Suspense } from 'react';

import { connect } from 'react-redux';

import * as uiActions from '../../store/actions/ui';
import * as configActions from '../../store/actions/config';
import * as httpActions from '../../store/actions/http';

import LengthOptions from '../LengthOptions/LengthOptions';
import ChainSelections from '../ChainSelections/ChainSelections';
import GeneralSettings from '../GeneralSettings/GeneralSettings';

import SidebarContainer from './SidebarContainer/SidebarContainer';
import SidebarEntry from './SidebarEntry/SidebarEntry';

import Page from '../../components/Page/Page';
import ControlPanel from '../../components/ControlPanel/ControlPanel';
import Attachment from '../../components/Attachment/Attachment';
import Notifications from '../../components/Notifications/Notifications';



import styles from './Editor.module.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faPlus,
  faExchangeAlt,
  faTape,
  faFileInvoiceDollar,
  faCloudDownloadAlt,
  faCogs,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faInfoCircle,
  faTrash,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBars,
  faPlus,
  faExchangeAlt,
  faTape,
  faFileInvoiceDollar,
  faCloudDownloadAlt,
  faCogs,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faInfoCircle,
  faTrash,
  faCheck
);

// let Quotation = null;
const Quotation = React.lazy(() => import('../../components/Quotation/Quotation'));
// const conditionalImports = () => {
//   // console.log(process.env.REACT_APP_USER === 'renold');
//   if (process.env.REACT_APP_USER === 'renold') {
//     import('../../components/Quotation/Quotation').then(v => {
//       // console.log(v)
//       Quotation = v.default;
//     });
//   }
// }
// conditionalImports();

class Editor extends Component {
  componentDidMount() {
    this.props.fetchGlobalSelectionsData();
    // console.log(this.props.preload)
    this.props.fetchPreview();
  }

  get attachmentTypes() {
    return this.props.availableAttachmentTypes || [];
  }

  addAttachment = props => {
    return (
      <Attachment
        {...props}
        mode="add"
        onClickAddAttachment={this.props.handleAddAttachment}
        attachmentTypes={this.attachmentTypes}
        existingAttachments={this.props.config.attachments}
        repeatableSection={this.props.previewData.repeatableSection}
      />
    );
  };

  chainSelections = props => (
    <ChainSelections
      cancelModification={this.props.cancelModification}
      applyModification={this.props.applyModification}
    />
  );

  editAttachment = props => {
    return (
      <Attachment
        mode="edit"
        onClickAddAttachment={this.props.handleAddAttachment}
        onDeleteAttachment={this.props.handleDeleteAttachment}
        attachmentTypes={this.attachmentTypes}
        existingAttachments={this.props.config.attachments}
        repeatableSection={this.props.previewData.repeatableSection}
      />
    );
  };

  generalSettings = props => {
    return (
      <GeneralSettings
        {...props}
        cancelModification={this.props.cancelModification}
        applyModification={this.props.applyModification}
      />
    );
  };

  lengthOptions = props => (
    <LengthOptions
      cancelModification={this.props.cancelModification}
      applyModification={this.props.applyModification}
    />
  );


  quotation = props => {

    if (!this.props.previewData.quotation) {
      return null;
    }
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Quotation
          {...props}
          partNumber={this.props.previewData.partNumber}
          matchingPartNumbers={this.props.previewData.partNumberMatches}
          unitOfMeasure={this.props.previewData.unitOfMeasure}
          salesOffice={this.props.config.salesOffice}
          isACCSupportedAtSalesOffice={
            this.props.previewData.isACCSupportedAtSalesOffice
          }
          additionalPartNumbers={this.props.previewData.additionalPartNumbers}
          showIconLabels={this.props.ui.showIconLabels}
          onLoad={this.props.loadPartNumber}
          onChangeConfig={this.props.handleChangeConfigSelection}
          {...this.props.previewData.quotation}
        />
      </Suspense>
    );
  };

  render() {

    const svg = !!this.props.previewData ? (
      <Page
        id="svg-preview"
        hideChainInfo={this.props.ui.hideChainInfo}
        file={this.props.previewData.file}
        // base64File={this.props.previewData.base64File}
        width={this.props.previewData.width}
        height={this.props.previewData.height}
        scale={this.props.previewData.scale}
        loadingLabel={this.props.loadingLabel}
        loading={this.props.loading}
      />
    ) : null;

    const pageContainerStyle = [styles.PageContainer];

    return (
      <div className={styles.Editor}>
        <div className={styles.Nav}>
          <ControlPanel
            isValid={this.props.previewData.isValid}
            numAttachments={this.props.config.attachments.length}
            onToggleSidebar={this.props.handleToggleSidebar}
            onAddAttachment={this.props.handleAddAttachment}
            generatePdf={this.props.generatePdf}
            showIconLabels={this.props.ui.showIconLabels}
            hasQuote={!!this.props.previewData.quotation}
          />
        </div>
        <div className={styles.Display}>
          <SidebarContainer
            onClose={this.props.handleToggleSidebar}
            sidebarTarget={this.props.ui.sidebarTarget}
          >
            <SidebarEntry
              name="chainSelections"
              title="Chain Selections"
              render={this.chainSelections}
            />
            <SidebarEntry
              name="lengthOptions"
              title="Length Options"
              render={this.lengthOptions}
            />
            <SidebarEntry
              name="addAttachment"
              title="Add Attachment"
              render={this.addAttachment}
            />
            <SidebarEntry
              name="editAttachment"
              title="Edit Attachment"
              render={this.editAttachment}
            />
            <SidebarEntry
              name="generalSettings"
              title="General Settings"
              render={this.generalSettings}
            />
            {/* {null} */}
            <SidebarEntry
              name="quotation"
              title="Quotation"
              render={this.quotation}
              onToggleSidebar={this.props.handleToggleSidebar}
            />
          </SidebarContainer>
          {/* </div> */}
          <div className={[pageContainerStyle.join(' ')]}>
            {svg}
            <Notifications
              show={this.props.ui.showNotifications}
              onDismiss={this.props.handleDismissNotification}
              connectionError={this.props.connectionError}
              missingComponents={this.props.previewData.missingComponents}
              valid={this.props.previewData.isValid}
              error={this.props.previewData.isError}
              chainSize={this.props.config.chainSize}
              deleteAttachment={this.props.handleDeleteAttachment}
              savePdf={() =>
                this.props.savePdf(this.props.pdf, this.props.config.chainSize)
              }
              pdf={this.props.pdf}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ui: state.ui,
    config: state.config,
    previewData: state.http.previewData,
    availableAttachmentTypes: state.http.previewData.attachmentTypes,
    loading: state.http.loading,
    loadingLabel: state.http.loadingLabel,
    pdf: state.http.pdf,
    connectionError: state.http.connectionError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleAddAttachment: (value, editingIndex) => {
      return dispatch(configActions.addAttachment(value, editingIndex));
    },
    handleDeleteAttachment: index =>
      dispatch(configActions.deleteAttachment(index)),
    handleToggleSidebar: value => dispatch(uiActions.toggleSidebar(value)),
    fetchPreview: () => dispatch(httpActions.fetchPreview()),
    fetchGlobalSelectionsData: () =>
      dispatch(httpActions.fetchGlobalSelectionsData()),
    generatePdf: svg => dispatch(httpActions.generatePdf(svg)),
    savePdf: (pdf, chainSize) => dispatch(httpActions.savePdf(pdf, chainSize)),
    cancelModification: () => dispatch(configActions.cancelModification()),
    applyModification: () => dispatch(configActions.applyModification()),
    handleDismissNotification: notificationKey => {
      return dispatch(uiActions.dismissNotification(notificationKey));
    },
    loadPartNumber: () => {
      return dispatch(configActions.loadPartNumber());
    },
    handleChangeConfigSelection: (key, val) =>
      dispatch({
        type: configActions.CHANGE_CONFIG_SELECTIONS,
        [key]: val,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Editor);
