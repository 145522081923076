import React from 'react';

// import fa from '../../../node_modules/@fortawesome/fontawesome-free/js/all.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './Icon.module.scss';

import IconLabel from './IconLabel/IconLabel';

function Icon(props) {

  const iconLabel = props.showLabel ?
    <IconLabel label={props.label} /> : null;


  return (
    <div className={classes.Icon}>
      {/* <div className={`${fa.fas} ${fa[`fa-${props.size || '2x'}`]} ${fa[props.icon]}`}></div> */}
      <FontAwesomeIcon icon={props.icon} size={props.size || '2x'} />
      { iconLabel }
    </div>
  );
}

export default Icon;