import React from 'react';

import classesFormElements from '../FormElements.module.scss';
import classes from './Input.module.scss';

function Input(props) {

  function returnValue(event) {
    if (props.type === 'integer') {
      event.target.value = event.target.value.replace(/[^0-9]/gi, '');
    }

    props.onChange(props.keyName, event.target.value);
  }

  function type() {
    switch (props.type.toLowerCase()) {
      case 'integer': return 'text';
      default: return props.type;
    }
  }

  const renderLabel = props.label ?
      <label className={classes.Label}>{props.label}</label> : null;

  const additionalStyle = props.width ? {
    width: props.width
  } : null;

  const show = props.show === undefined ? true : props.show;
  if (!show) {
    return null;
  }

  return (
    <div className={classesFormElements.Field} style={additionalStyle}>
      {renderLabel}
        <input className={`${classesFormElements.FixedHeight} ${classes.Input}`}
          type={type()}
          value={props.value}
          placeholder={props.placeholder}
          onChange={returnValue}
          min={props.min}
          max={props.max}
          step={props.step}
          list={props.datalistId}
          disabled={props.disabled}
        ></input>
    </div>
  );
}

export default Input;