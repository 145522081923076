import React, { Component } from 'react';

import classes from './Select.module.scss';
import classesFormElements from '../FormElements.module.scss';

import { isEqual } from 'lodash';

class Select extends Component {

  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  handleSelectOption = (event) => {
    const selection = this.props.multiple ?
      [...event.target.options].filter(o => o.selected).map(o => o.value) :
      event.target.value;
    
     this.props.onChange(this.props.keyName, selection);
  }

  selectedValue(value, options) {

    if (options.length === 0) {
      return value;
    }

    if (options[0].type === 'optgroup') {
        return options.some(o => o.props.children.some(c => c.key === value)) ? value : this.props.onChange(this.props.keyName, options[0].props.children[0].key);
    }

    const match = value instanceof Array ? options.some(o => value.some(v => v.toString() === o.key)) : options.some(o => o.key === value);
    const overrideVal = value instanceof Array ? [options[0].key] : value;

    return match ? value : this.props.onChange(this.props.keyName, overrideVal);

  }

  shouldComponentUpdate(prevProps) {
    return prevProps.value !== this.props.value || !isEqual(prevProps.options, this.props.options);
  }

  buildOptions () {
    // const optionGroups = this.props.sortKey.forEach;
    const optGroups = [];
    for(let sortKeyEntry of this.props.sortKey) {
      

      
      const groupedOptionEntries = this.props.options
        .filter(sortKeyEntry.filter);
      
      if (groupedOptionEntries.length === 0) {
        continue;
      }
      
      // console.log(groupedOptionEntries, sortKeyEntry.filter);
      const groupName = sortKeyEntry.group(groupedOptionEntries[0]);
      
      groupedOptionEntries.sort(sortKeyEntry.sort);
      
      
      const opts = groupedOptionEntries.map(opt => {
        const disabled = this.props.disabledOptions &&
          this.props.disabledOptions.includes(opt.value);
        return (
          <option key={opt.displayValue}
            value={opt.value}
            disabled={disabled}>{opt.displayValue || opt.value}
          </option>
        )
      });
      
      if (groupedOptionEntries.length > 0) {
        if (optGroups[groupName]) {
          optGroups[groupName].push(...opts);
        } else {
          optGroups[groupName] = opts;
        }
      }
    }

    const renderedOptions = [];

    for (let item in optGroups) {
      const groupItem = <optgroup label={item} key={optGroups[item]}>{optGroups[item]}</optgroup>;
      renderedOptions.push(groupItem);
    }

    // console.log('Select', 'Rendered options:', renderedOptions)
    return renderedOptions;
  }

  render() {

    let renderedOptions;
    
    if (this.props.sortKey && this.props.options.length > 0) {
      renderedOptions = this.buildOptions();
    } else {

      renderedOptions = this.props.options.map(opt => {

        const disabled = this.props.disabledOptions && this.props.disabledOptions.indexOf(opt.value) > -1; 
  
        return <option key={opt.value} value={opt.value} disabled={disabled}>{opt.displayValue || opt.value}</option>;
      });
    }
    
    
  
    const renderLabel = this.props.label ? (
      <label className={classes.Label}>
      {this.props.label}
        {
          this.props.multiple ?
          <span className={classesFormElements.DesktopOnly}>
            &nbsp;(Click and drag or hold CTRL to select multiple options)
          </span> :
          null
        }
      </label>
     ) : null;

    const classesToApply = [classes.Select];
    if (!this.props.multiple) {
      classesToApply.push(classesFormElements.FixedHeight);
    }

    // console.log('selected val', this.selectedValue(this.props.value, renderedOptions))

    const size = this.props.multiple ? this.props.size || 3 : 1;

    return (
      <div className={classesFormElements.Field}>
        {renderLabel}
        <div className={classes.SelectContainer} disabled={this.props.disabled}>
          <select className={classesToApply.join(' ')} 
            value={this.selectedValue(this.props.value, renderedOptions)}
            onChange={this.handleSelectOption}
            disabled={this.props.disabled}
            multiple={!!this.props.multiple}
            size={size}>
            {renderedOptions}
          </select>
        </div>
      </div>
    );
  }
  
}

export default Select;