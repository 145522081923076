import { toggleSidebar } from "./ui";
import { fetchPreview } from "./http";

// export const CHANGE_CONFIG_SELECTION = 'CHANGE_CONFIG_SELECTION';
export const CHANGE_CONFIG_SELECTIONS = 'CHANGE_CONFIG_SELECTIONS';
export const CANCEL_MODIFICATION = 'CANCEL_MODIFICATION';
export const APPLY_MODIFICATION = 'APPLY_MODIFICATION';

export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';

export const LOAD_PART_NUMBER = 'LOAD_PART_NUMBER';
export const SAVE_PART_NUMBER = 'SAVE_PART_NUMBER';
export const OVERWRITE_PREVIEW_PROPERTIES = 'OVERWRITE_ATTACHMENTS';

export const addAttachment = (value, editingIndex) => {
  return dispatch => {
    dispatch(toggleSidebar());
    dispatch({
      type: ADD_ATTACHMENT,
      value: value,
      editingIndex: editingIndex
    });
    dispatch(fetchPreview());
  }
}

export const deleteAttachment = (index) => {
  return dispatch => {
    dispatch(toggleSidebar());
    dispatch({
      type: DELETE_ATTACHMENT,
      index: index
    });
    dispatch(fetchPreview());
  }
}

export const cancelModification = (index) => {
  return dispatch => {
    dispatch(toggleSidebar());
    dispatch({
      type: CANCEL_MODIFICATION
    });
  }
}

export const applyModification = (index) => {
  return dispatch => {
    dispatch({
      type: APPLY_MODIFICATION
    });
    dispatch(fetchPreview());
  }
}

export const loadPartNumber = (partNumber) => {
  return dispatch => {
    dispatch({
      type: LOAD_PART_NUMBER,
      partNumber: partNumber
    });
    // console.log(partNumber);
    dispatch(fetchPreview('load'));
  }
}

export const savePartNumber = (partNumber, additionalPartNumbers) => {
  return dispatch => {
    dispatch({
      type: SAVE_PART_NUMBER,
      partNumber: partNumber,
      additionalPartNumbers: additionalPartNumbers
    });
    dispatch(fetchPreview('save'));
  }
}

export const overwritePreviewProperties = (data) => {
  // console.log(data);
  return dispatch => {
    dispatch({
      type: OVERWRITE_PREVIEW_PROPERTIES,
      attachments: data.attachments,
      partNumber: data.partNumber,
      additionalPartNumbers: data.additionalPartNumbers,
      numberRepeats: data.numberRepeats,
      chainSize: data.chainSize,
      chainBrand: data.chainBrand,
      chainType: data.chainType,
      unitOfMeasure: data.unitOfMeasure,
      finishingLink: data.finishingLink,
      isFinishingLinkSeparate: data.isFinishingLinkSeparate,
      rollerType: data.rollerType,
      flatted: data.flatted,
      pitch: data.pitch,
    });
  }
}


export const setChainType = (chainType) => {
  return (dispatch, getState) => {
    const selections = getState().http.globalSelections;
    const chain = selections.availableSizes.find(c => c.collection.name === chainType);
    // console.log('TEST', chain, chainType, chain.collection.name);

    dispatch({
      type: CHANGE_CONFIG_SELECTIONS,
      chainType,
      finishingLink: chain.standardConnectingLink,
    });

    if (getState().config.chainType !== chainType) {
      return;
    }

    dispatch({
      type: CHANGE_CONFIG_SELECTIONS,
      finishingLink: chain.standardConnectingLink,
    });

    dispatch(setChainBrand(chainType, chain.brand));
    dispatch(fetchPreview());
  }
}


function getChain(getState, chainType, chainBrand) {
  const selections = getState().http.globalSelections;
  return selections.availableSizes.find(c => c.collection.name === chainType && c.brand === chainBrand);
}

function getRollerType(getState, chain) {
  const curConfig = getState().config;
  const rollerMatch = chain.rollerOptions.find(r => curConfig.rollerType === r.value);

  return rollerMatch ? curConfig.rollerType : chain.rollerOptions[0].value;
}

function getFlatted(getState, chain) {
  const curConfig = getState().config;
  // console.log(curConfig, chain)
  return chain.hasFlattedVersion && curConfig.flatted;
}

export const setChainBrand = (chainType, chainBrand) => {
  return (dispatch, getState) => {

    const chain = getChain(getState, chainType, chainBrand);
    const rollerType = getRollerType(getState, chain);
    const flatted = getFlatted(getState, chain);

    
    dispatch({
      type: CHANGE_CONFIG_SELECTIONS,
      chainBrand: chain.brand,
      chainSize: chain.name,
      pitch: chain.pitches ? chain.pitches[0].toString() : '',
      rollerType,
      flatted,
    });
  }
}


export const setChainSize = (chainSize) => {
  return (dispatch, getState) => {
    const selections = getState().http.globalSelections;
    const chain = selections.availableSizes.find(c => c.name === chainSize);
    
    // Keep same roller type if possible
    // console.log('sfdds', chain.name, chain.brand);
    const rollerType = getRollerType(getState, chain);
    const flatted = getFlatted(getState, chain);

    dispatch({
      type: CHANGE_CONFIG_SELECTIONS,
      chainSize,
      pitch: chain.pitches ? chain.pitches[0].toString() : chain.pitch,
      rollerType,
      flatted,
    });
  }
}