import React from 'react';

import classes from './ActionBar.module.scss';

function actionBar(props) {

  const actions = React.Children.map(props.children, a => {
    return (
      <div className={classes.ActionChild}>{a}</div>
    );
  });

  return (
    <div className={classes.ActionBar}>
      {actions}
    </div>
  );
}

export default actionBar;
