import React from 'react';

import { setClassNames } from '../../../helpers';
import classes from './Button.module.scss';

import Icon from '../../Icon/Icon';

function Button({ children, onClick, disabled, type, desc, btnStyle, onMouseOver, onMouseOut, link, ...icon}) {

  btnStyle = btnStyle || '';

  function prepareClasses() {
    if (!!link) {
      return [classes['Link']];
    }

    return ('Button ' + btnStyle).split(' ').map(s => classes[s]);
  }

  const iconWithTextClass = !!children ? classes.WithText : false;
  const iconEl = !!icon.icon
    ? (
      <div className={setClassNames(classes.Icon, iconWithTextClass)}>
        <Icon { ...icon } />
      </div>
    )
    : null;

  return (
    <button
      className={setClassNames(...prepareClasses())}
      onClick={onClick}
      onMouseOver={onMouseOver || null}
      onMouseOut={onMouseOut || null}
      disabled={disabled}
      type={type || 'button'}
      title={desc}>
        {iconEl}{children}
    </button>
  );
}

export default Button;