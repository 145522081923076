import React, { Children, Component } from 'react';

import Button from '../../../components/FormElements/Button/Button';
import Icon from '../../../components/Icon/Icon';

import classes from './SidebarContainer.module.scss';

class SidebarContainer extends Component {

  state = {
    contents: []
  };

  componentDidMount() {
    const contents = [];
    Children.forEach(this.props.children, c => {
      if (!!c) {
        contents.push(c.props.name);
      }
    });

    // console.log(contents)

    this.setState({
      contents: contents
    });
  }

  componentWillUnmount() {
    this.setState({
      isCancelled: true
    });
  }

  handleChangeTarget = (newTarget) => {
    this.setState({
      target: newTarget
    });
  }

  handleClose() {
    this.setState({
      isCancelled: true,
    });
    this.props.onClose();
  }

  render() {

    const itemToRender = Children.toArray(this.props.children).find(c => {
      return c.props.name === this.props.sidebarTarget
    });

    if (!itemToRender) { return null; }

    const container = [classes.Root];
    !!this.props.sidebarTarget ? container.push(classes.Open) : container.push(classes.closed);

    return (
      <div className={container.join(' ')}>
        <div className={classes.Sidebar}>
          <div className={classes.Heading}>
            <h3>
              {itemToRender.props.title}
            </h3>
            <div className={classes.Close}>
              <Button onClick={() => this.handleClose()} btnStyle="Icon"><Icon icon="times" size="lg" /></Button>
            </div>
          </div>
          <div className={classes.Content}>
            {itemToRender}
          </div>
        </div>
      </div> 
    );
  }

}

export default SidebarContainer;