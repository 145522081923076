import React, { PureComponent } from 'react';

import Icon from '../../Icon/Icon';
import ActionBar from '../../ActionBar/ActionBar';
import { Button } from '../../FormElements';

import classes from './Notification.module.scss';

class Notification extends PureComponent {

  state = {
    dismissTimer: -1
  }

  constructor(props) {
    super(props);
    this.notification = React.createRef();
  }

  autoDismiss() {
    const timer = setTimeout(() => {
      this.props.onDismiss();
    }, this.props.dismiss);
    
    return timer;
  }

  componentDidMount() {
    if (this.props.dismiss && typeof this.props.dismiss === 'number') {
      this.setState({
        dismissTimer: this.autoDismiss()
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      clearTimeout(this.state.dismissTimer);
      if (this.props.dismiss && typeof this.props.dismiss === 'number') {
        this.setState({
          dismissTimer: this.autoDismiss()
        });
      }
    } 
  }

  handleHide = () => {
    this.props.onDismiss();
    clearTimeout(this.state.dismissTimer);

    if (this.props.dismissAction) {
      this.props.dismissAction();
    }
  }

  render() {

    if (!this.props.show) {
      return null;
    }

    const getIcon = () => {
      switch(this.props.type) {
        case 'Warning': return 'exclamation-circle';
        case 'Success': return 'check-circle';
        case 'Failure': return 'times-circle';
        default: return 'info-circle';
      }
    }

    const type = this.props.type || "Info";
    const icon = getIcon();

    return (
      <div ref={this.notification} 
        className={classes.Notification}>
          <div className={[classes.IconBar, classes.Padding, classes[type]].join(' ')}>
            <Icon icon={icon} size="2x" />
          </div>
          <div className={[classes.ContentContainer, classes.Padding, classes[type]].join(' ')}>
            <div className={classes.Title}>{this.props.title}</div>
            <div className={classes.Content}>
              {this.props.children}
            </div>
            <ActionBar>
              <Button btnStyle={`Notification ${this.props.type}`} onClick={this.handleHide}>{this.props.dismissButtonLabel || 'DISMISS'}</Button>
            </ActionBar>
          </div>
      </div>
    );
  }
}

export default Notification;