import React from 'react';
import { Select } from '../../components/FormElements';

const PitchSelection = ({ options, pitch, onChangeSelection }) => {
	if (!options || !options.length) {
		return null;
	}

	return (
		<Select label="Pitch" options={options}
			keyName="pitch"
			onChange={onChangeSelection} value={pitch}
		/>
	);
}

export default PitchSelection;