import React from 'react';

import Notification from './Notification/Notification';

import classes from './Notifications.module.scss';

const notifications = props => {
  const missingComponents = props.missingComponents.map((c, index) => {
    return (
      <Notification
        key={c.index}
        title="Missing Component"
        show={true}
        onDismiss={() => props.onDismiss('missingComponent')}
        type="Warning"
        dismissButtonLabel="DELETE"
        dismissAction={() => props.deleteAttachment(c.index)}
      >
        {c.type} is not available for {props.chainSize} ({c.pitch} mm). Either delete this
        component or select another chain.
      </Notification>
    );
  });

  const error = (
    <Notification
      key="Server Error"
      title="Server Error"
      show={props.show.error}
      onDismiss={() => props.onDismiss('error')}
      type="Failure"
    >
      There was a problem building this configuration. Please try again or alter
      the selections. If the problem persists, please contact a Renold
      representative.
    </Notification>
  );

  const connectionError = !!props.show.connectionError ? (
    <Notification
      title="Connection Error"
      show={!!props.show.connectionError}
      onDismiss={() => props.onDismiss('connectionError')}
      type="Failure"
    >
      Error: {props.connectionError}
      <p>
        Please try again later. If the problem persists, please contact a Renold
        representative.
      </p>
    </Notification>
  ) : null;

  const pdf = (
    <Notification
      key="PDF Creation Successful"
      title="PDF Creation Successful"
      show={props.show.pdf}
      onDismiss={() => props.onDismiss('pdf')}
      dismissButtonLabel="DOWNLOAD"
      dismissAction={props.savePdf}
      type="Success"
    >
      Click to download
    </Notification>
  );

  const valid = props.valid ? (
    <Notification
      key="Preview successful"
      title="Preview successful"
      show={
        props.show.valid && missingComponents.length === 0 && !props.show.pdf
      }
      onDismiss={() => props.onDismiss('valid')}
      dismiss={3000}
      type="Success"
    />
  ) : (
    <Notification
      key="Invalid Configuration"
      title="Invalid Configuration"
      show={props.show.invalid}
      onDismiss={() => props.onDismiss('invalid')}
      type="Failure"
    >
      The selected configuration is not valid. Please adjust the selections and
      try again.
    </Notification>
    );
  
    const tooLarge = (
      <Notification
        key="Too Large"
        title="Too large"
        show={props.show.tooLarge}
        onDismiss={() => props.onDismiss('tooLarge')}
        dismissButtonLabel="DISMISS"
        // dismissAction={props.savePdf}
        type="Warning"
      >
        The configuration you selected is too large to be drawn.
        <p>
          Please contact the Renold sales team to if you would like further assistance.
        </p>
      </Notification>
    );

  const result = !!connectionError
    ? connectionError
    : [missingComponents, valid, error, pdf, tooLarge];

  return (
    <div className={classes.Notifications}>
      <div className={classes.Container}>
        <div className={classes.Content}>{result}</div>
      </div>
    </div>
  );
};

export default notifications;
